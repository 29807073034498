<div class="dropdown">
    <div class="button__parent">
        <button class="dropdown-btn btn" (click)="toggleDropdown()">
            {{ defaultName }}
            <fa-icon [icon]="faChevronDown" />
        </button>
        @if (useHits) {
            @if (hits$ | async; as hits) {
                <div class="sort-btn" (click)="toggleHits(hits)">
                    <fa-icon [icon]="faSortUp" [class.active]="hits === 'ascending'" />
                    <fa-icon [icon]="faSortDown" [class.active]="hits === 'descending'" />
                </div>
            }
        }
    </div>
    <div class="menu" [class.open]="isOpened">
        @for (item of items; track item) {
            <div class="dropdown-item" [class.active]="defaultName === item.name" (click)="select(item)">
                {{ item.name }}
            </div>
        }
    </div>
</div>
